.footer {
  background-color: #4f5754;
  color: #ffffff;
  width: 100%;
  top: 0;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.footer .footer-inner {
  padding: 1em;
  text-align: center;
}

.footer-link {
  color: white;
  text-decoration: none;
}