.technology-sector {
  display: block;
  height: 50%;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.technology-sector--inner {
  display: flex;
  flex-wrap: wrap;
}

.technology-sector--inner div {
  display: inline-block;
  width: 50%;
}

.technology-navigation {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  display: block;
  right: 0;
  bottom: 0;
}

.actions {
  height: 100%;
}

.technology-navigation ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  width: 100vw;
}

.technology-navigation ul li {
  border: 0.1em solid white;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.technology-navigation ul li a {
  font-size: 1.25em;
  text-transform: uppercase;
  color: #ffffff;
}