@import url(https://fonts.googleapis.com/css?family=Hind+Siliguri:300,400,700&display=swap);
.main-header-content {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 80rem;
  margin: 0 auto;
}
.main-header-content ul, .main-header-content li {
  list-style: none;
}
.main-header-content .main-menu__item {
  color: #ffffff;
  position: relative;
  font-size: 1.35em;
}
@media (max-width: 767px) {
  .main-header-content nav {
    display: none;
  }
}
.main-header-content nav > ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: -1em;
  padding: 0 1em;
}
.main-header-content nav > ul .main-menu__item.current-page:after {
  bottom: 0.1em;
}
.main-header-content li {
  padding: 0 1em;
}

.site-entered .main-header-content {
  transition: margin-top 0.35s ease-in-out;
  margin-top: 0;
}
.site-entered .Main-Menu-navigation {
  top: -100vh;
  transition: margin-top 0.35s ease-in-out;
}

.at-start-page .main-header-content {
  height: 20vh;
  margin-top: -20vh;
  transition: margin-top 0.35s ease-in-out;
}
.at-start-page .Main-Menu-navigation {
  height: 100vh;
  top: 0;
  transition: top 0.35s ease-in-out;
}

.site-logo {
  color: white;
  height: 100%;
  font-size: 2em;
  padding: 0 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.site-logo a {
  color: #ffffff;
  text-decoration: none;
}

.go-to-start-page {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 1em;
  font-size: 1rem;
  color: #ffffff;
  border: 0;
}
.go-to-start-page:after {
  background-color: transparent;
  background-image: url(/static/media/double-arrow.a1fd5dd4.svg);
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  background-position: center;
  bottom: 0em;
}
.go-to-start-page:hover:after {
  bottom: 0em;
}
@media (min-width: 768px) {
  .go-to-start-page {
    display: none;
  }
}

.Main-Menu-navigation {
  height: 100%;
  width: 100%;
  position: absolute;
}
.Main-Menu-navigation a {
  font-size: 2em;
}

.Main-Menu-navigation ul {
  list-style: none;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .Main-Menu-navigation ul {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.Main-Menu-navigation li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .Main-Menu-navigation li {
    width: 33.33%;
  }
}

.Main-Menu-navigation li:first-child {
  transition: all 0.35s linear;
}

.Main-Menu-navigation li:after {
  background-color: rgba(79, 87, 84, 0.2);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.active-menu-state li:after {
  opacity: 0.2;
}

.active-menu-state li.active:after {
  opacity: 0.5;
}

.active-menu-state li.active {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 50%;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  transition: all 0.35s linear;
}
@media (min-width: 768px) {
  .active-menu-state li.active {
    height: 100%;
  }
}

.active-menu-state li.first-menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 50%;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  transition: all 0.35s linear;
}
@media (min-width: 768px) {
  .active-menu-state li.first-menu-item {
    height: 100%;
  }
}

.Main-Menu-navigation li:nth-child(odd) {
  background-color: #000000;
}

.Main-Menu-navigation li:nth-child(even) {
  background-color: #af6969;
}

.Main-Menu-navigation li img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
  opacity: 0.6;
  max-width: none;
}
@media (min-width: 768px) {
  .Main-Menu-navigation li img {
    height: 100%;
    width: auto;
  }
}

.Main-Menu-navigation li a {
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.App-header {
  overflow: hidden;
  background-color: #282c34;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  z-index: 2;
  position: relative;
  transition: height 0.35s linear;
}

.App-header.site-entered {
  height: 15vh;
  transition: height 0.35s linear;
}
.App-header.site-entered .Main-Menu-navigation {
  transition: height 0.35s linear;
}

.technology-sector {
  display: block;
  height: 50%;
  width: 50%;
}
.technology-sector {
  display: block;
  height: 50%;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.technology-sector--inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.technology-sector--inner div {
  display: inline-block;
  width: 50%;
}

.technology-navigation {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  display: block;
  right: 0;
  bottom: 0;
}

.actions {
  height: 100%;
}

.technology-navigation ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  width: 100vw;
}

.technology-navigation ul li {
  border: 0.1em solid white;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.technology-navigation ul li a {
  font-size: 1.25em;
  text-transform: uppercase;
  color: #ffffff;
}
.portfolio-menu {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  display: block;
  right: 0;
  bottom: 0;
}

.portfolio-menu ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  width: 100vw;
}

.portfolio-menu ul li {
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  position: relative;
  transition: width 0.35s ease-in-out;
}

.portfolio-menu ul li:hover .portfolio-image {
  width: 100%;
  transition: width 0.35s ease-in-out;
}

.portfolio-menu .portfolio-image {
  width: 90%;
  position: relative;
  overflow: hidden;
  height: 100%;
  text-align: left;
  margin: 0;
  height: 6rem;
  transition: width 0.35s ease-in-out;
}

.portfolio-menu ul li img {
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.portfolio-menu ul li:nth-child(even) {
  background-color: #333333;
  border-top: 0.35em solid #ffffff;
}

.portfolio-menu ul li:nth-child(odd) {
  background-color: #333333;
  border-top: 0.35em solid #ffffff;
}

.portfolio-menu ul li a {
  color: #ffffff;
  position: relative;
  z-index: 0;
  text-decoration: none;
  text-align: left;
  padding: 1em;
  width: 70%;
}
.portfolio-menu ul li a:after {
  display: none;
}

.portfolio-menu {
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  display: block;
  right: 0;
  bottom: 0;
}
.portfolio-menu .work-type {
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  padding: 0 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  width: auto;
  background-color: rgba(0, 0, 0, 0.54);
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.assistive {
  margin-left: -9999px;
  opacity: 0;
  position: absolute;
}

body {
  font-family: "Hind Siliguri", sans-serif;
  margin: 0;
  font-size: 1em;
  overflow-x: hidden;
}

.Page-Title {
  text-transform: capitalize;
}

a {
  position: relative;
  text-decoration: none;
}
a:after {
  background: #ffffff;
  content: "";
  width: 100%;
  height: 2px;
  transition: all 0.35s ease-in-out;
  position: absolute;
  left: 0;
  bottom: -0.25em;
}
a:hover:after {
  opacity: 1;
  bottom: -0.1em;
  transition: all 0.35s ease-in-out;
}

.contain {
  margin: 0 auto;
  max-width: 44rem;
}

body {
  height: 100vh;
}

.page-content-animation-wrapper {
  background-color: #ffffff;
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 1;
  text-align: left;
}

.page-content {
  height: 85vh;
  background-color: #ffffff;
  left: 0;
  top: 0;
  z-index: 1;
  text-align: left;
}
.page-content.contain, .page-content .contain {
  padding: 1em;
}

.footer {
  height: 70px;
}

.page-content.technology {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  width: 100vw;
}

.page-content.technology > div {
  border: 0.1em solid white;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  width: 50%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100vw;
}

.page-content.portfolio > .portfolio-content-wrapper {
  border: 0.1em solid white;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  width: 50%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100vw;
}

.page-content.portfolio > .contain {
  border: 0.1em solid white;
  height: 100%;
  width: 50%;
  width: 100vw;
}
.footer {
  background-color: #4f5754;
  color: #ffffff;
  width: 100%;
  top: 0;
  height: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
}

.footer .footer-inner {
  padding: 1em;
  text-align: center;
}

.footer-link {
  color: white;
  text-decoration: none;
}
