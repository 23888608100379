body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.assistive {
  margin-left: -9999px;
  opacity: 0;
  position: absolute;
}

body {
  font-family: "Hind Siliguri", sans-serif;
  margin: 0;
  font-size: 1em;
  overflow-x: hidden;
}

.Page-Title {
  text-transform: capitalize;
}

a {
  position: relative;
  text-decoration: none;
}
a:after {
  background: #ffffff;
  content: "";
  width: 100%;
  height: 2px;
  transition: all 0.35s ease-in-out;
  position: absolute;
  left: 0;
  bottom: -0.25em;
}
a:hover:after {
  opacity: 1;
  bottom: -0.1em;
  transition: all 0.35s ease-in-out;
}

.contain {
  margin: 0 auto;
  max-width: 44rem;
}
