.main-header-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 80rem;
  margin: 0 auto;
}
.main-header-content ul, .main-header-content li {
  list-style: none;
}
.main-header-content .main-menu__item {
  color: #ffffff;
  position: relative;
  font-size: 1.35em;
}
@media (max-width: 767px) {
  .main-header-content nav {
    display: none;
  }
}
.main-header-content nav > ul {
  display: flex;
  justify-content: space-between;
  margin-right: -1em;
  padding: 0 1em;
}
.main-header-content nav > ul .main-menu__item.current-page:after {
  bottom: 0.1em;
}
.main-header-content li {
  padding: 0 1em;
}

.site-entered .main-header-content {
  transition: margin-top 0.35s ease-in-out;
  margin-top: 0;
}
.site-entered .Main-Menu-navigation {
  top: -100vh;
  transition: margin-top 0.35s ease-in-out;
}

.at-start-page .main-header-content {
  height: 20vh;
  margin-top: -20vh;
  transition: margin-top 0.35s ease-in-out;
}
.at-start-page .Main-Menu-navigation {
  height: 100vh;
  top: 0;
  transition: top 0.35s ease-in-out;
}

.site-logo {
  color: white;
  height: 100%;
  font-size: 2em;
  padding: 0 1em;
  display: flex;
  align-items: center;
}
.site-logo a {
  color: #ffffff;
  text-decoration: none;
}

.go-to-start-page {
  display: flex;
  align-items: center;
  padding: 1em;
  font-size: 1rem;
  color: #ffffff;
  border: 0;
}
.go-to-start-page:after {
  background-color: transparent;
  background-image: url("../icons/double-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  background-position: center;
  bottom: 0em;
}
.go-to-start-page:hover:after {
  bottom: 0em;
}
@media (min-width: 768px) {
  .go-to-start-page {
    display: none;
  }
}

.Main-Menu-navigation {
  height: 100%;
  width: 100%;
  position: absolute;
}
.Main-Menu-navigation a {
  font-size: 2em;
}

.Main-Menu-navigation ul {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
}
@media (min-width: 768px) {
  .Main-Menu-navigation ul {
    flex-direction: row;
  }
}

.Main-Menu-navigation li {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .Main-Menu-navigation li {
    width: 33.33%;
  }
}

.Main-Menu-navigation li:first-child {
  transition: all 0.35s linear;
}

.Main-Menu-navigation li:after {
  background-color: rgba(79, 87, 84, 0.2);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.active-menu-state li:after {
  opacity: 0.2;
}

.active-menu-state li.active:after {
  opacity: 0.5;
}

.active-menu-state li.active {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  justify-content: center;
  position: relative;
  transition: all 0.35s linear;
}
@media (min-width: 768px) {
  .active-menu-state li.active {
    height: 100%;
  }
}

.active-menu-state li.first-menu-item {
  display: flex;
  align-items: center;
  height: 50%;
  justify-content: center;
  position: relative;
  transition: all 0.35s linear;
}
@media (min-width: 768px) {
  .active-menu-state li.first-menu-item {
    height: 100%;
  }
}

.Main-Menu-navigation li:nth-child(odd) {
  background-color: #000000;
}

.Main-Menu-navigation li:nth-child(even) {
  background-color: #af6969;
}

.Main-Menu-navigation li img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
  opacity: 0.6;
  max-width: none;
}
@media (min-width: 768px) {
  .Main-Menu-navigation li img {
    height: 100%;
    width: auto;
  }
}

.Main-Menu-navigation li a {
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.App-header {
  overflow: hidden;
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
  position: relative;
  transition: height 0.35s linear;
}

.App-header.site-entered {
  height: 15vh;
  transition: height 0.35s linear;
}
.App-header.site-entered .Main-Menu-navigation {
  transition: height 0.35s linear;
}

.technology-sector {
  display: block;
  height: 50%;
  width: 50%;
}